<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="d-flex justify-space-between align-center w-full">
          <div class="me-3">
            Importer des adhérents
          </div>
          <div>
            <v-dialog
              v-model="dialogs.imports"
              max-width="700px"
              persistent
              @input="onDialogInput"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  Ajouter
                  <v-icon>{{ mdiPlus }}</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Nouvel import</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="cgp"
                        :items="conseillers"
                        :error="cgpError"
                        item-text="cabinet"
                        item-value="id"
                        label="CGP"
                        hide-details="auto"
                        outlined
                        :disabled="loading.processImport"
                        @change="setCgp"
                      ></v-select>
                    </v-col>
                    <v-col class="d-flex align-center">
                      <v-dialog
                        v-model="dialogs.conseiller"
                        max-width="600px"
                        persistent
                        @input="onDialogConseiller"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            outlined
                            v-bind="attrs"
                            large
                            block
                            v-on="on"
                          >
                            Ou créer un nouveau CGP
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>Ajouter un nouveau cabinet de CGP</v-card-title>
                          <v-card-text>
                            <v-form
                              ref="form"
                              class="multi-col-validation"
                              autocomplete="off"
                            >
                              <v-row>
                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                    v-model="conseillerFormData.cabinet"
                                    outlined
                                    dense
                                    label="Cabinet"
                                    :rules="[validators.required]"
                                    :error-messages="errorField['conseiller.cabinet']"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                    v-model="conseillerFormData.responsable"
                                    outlined
                                    dense
                                    label="Responsable"
                                    :rules="[validators.required]"
                                    :error-messages="errorField['conseiller.responsable']"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                    v-model="conseillerFormData.telephone"
                                    outlined
                                    dense
                                    label="Téléphone"
                                    :rules="[validators.required]"
                                    :error-messages="errorField['conseiller.telephone']"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                    v-model="conseillerFormData.email"
                                    outlined
                                    dense
                                    label="E-mail"
                                    :rules="[validators.required, validators.emailValidator]"
                                    :error-messages="errorField['conseiller.email']"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  class="d-flex justify-center mt-2"
                                >
                                  <v-btn
                                    color="primary"
                                    class="me-3"
                                    type="submit"
                                    :loading="loading.conseiller"
                                    @click.prevent="onSubmit"
                                  >
                                    Créer
                                  </v-btn>

                                  <v-btn
                                    outlined
                                    :disabled="loading.conseiller"
                                    color="secondary"
                                    @click.prevent="dialogs.conseiller = false"
                                  >
                                    Annuler
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-card
                        v-show="nbWaitingImports === 0"
                        class="excel-file"
                        :class="[{'active': !disabled},{ 'dragover': dragover }, { 'on-error': error }]"
                        outlined
                        :loading="loading.excelFile"
                        @drop.prevent="onDrop($event)"
                        @dragover.prevent="dragover = true"
                        @dragenter.prevent="dragover = true"
                        @dragleave.prevent="dragover = false"
                      >
                        <v-card-text>
                          <v-row
                            class="d-flex flex-column"
                            align="center"
                            justify="center"
                            dense
                          >
                            <v-col>
                              <v-icon
                                :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
                                size="60"
                              >
                                {{ mdiCloudUpload }}
                              </v-icon>
                              <p>
                                Glissez votre fichier ici ou <a @click="selectFile">cliquez ici pour envoyer</a>.<br>
                                <span class="caption">(Format xlsx uniquement).</span>
                                <input
                                  id="import-file"
                                  class="el-upload__input"
                                  type="file"
                                  name="file"
                                  multiple=""
                                  :accept="accept"
                                  @change="onFileSelected"
                                />
                              </p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card
                        v-if="nbWaitingImports > 0"
                        class="align-center mt-5"
                        outlined
                      >
                        <v-card-title class="text-center justify-center">
                          <p>Nombre d'adhérents à importer : {{ nbWaitingImports }}</p>
                        </v-card-title>
                        <v-card-text class="align-center">
                          <v-progress-circular
                            :rotate="360"
                            :size="100"
                            :width="15"
                            :value="importPercent"
                            :color="processFinished && importErrors.length === 0 ? 'success' : 'primary'"
                          >
                            <v-icon
                              v-if="processFinished && importErrors.length === 0"
                              large
                              color="success"
                            >
                              {{ mdiCheckBold }}
                            </v-icon>
                            <span v-else>{{ processedImports }}</span>
                          </v-progress-circular>
                          <div
                            v-if="processFinished"
                            class="text-center"
                          >
                            <h3 class="mt-5">
                              Importation terminée !
                            </h3>
                            <div v-if="importErrors.length === 0">
                              {{ processedImports }} nouvelles adhésions ont été ajoutée.
                            </div>
                            <div v-else>
                              <p>Des erreurs ont eu lieu et certains adhérents présents dans le fichier n'ont pas pu être importés.</p>
                              <p><strong>Détails des erreurs :</strong></p>
                              <div class="errors">
                                <ul>
                                  <li
                                    v-for="(error, i) in importErrors"
                                    :key="i"
                                  >
                                    <strong>{{ error.adherent }}</strong><br />{{ error.msg }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </v-card-text>
                        <v-card-actions
                          v-if="!processFinished"
                          class="justify-space-between align-center w-full"
                        >
                          <v-btn
                            outlined
                            :disabled="loading.processImport"
                            @click="removeFile"
                          >
                            Changer de fichier
                          </v-btn>

                          <v-btn
                            color="primary"
                            :loading="loading.processImport"
                            @click="callProcessImport"
                          >
                            <v-icon left>
                              {{ mdiDatabaseImport }}
                            </v-icon>
                            Démarrer l'importation
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    :disabled="loading.processImport"
                    :text="!processFinished"
                    @click="dialogs.imports = false"
                  >
                    {{ processFinished ? 'Fermer' : 'Annuler' }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>ID</th>
                <th>Cabinet CGP</th>
                <th>Date d'import</th>
                <th style="text-align: center;">
                  Adhésions importées
                </th>
                <th style="text-align: center;">
                  Payé
                </th>
                <th style="text-align: center;">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="imports.length === 0">
                <td
                  colspan="6"
                  class="text-center"
                >
                  Aucun import
                </td>
              </tr>
              <template v-else>
                <tr
                  v-for="(item, ind) in imports"
                  :key="item.id"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.conseiller.cabinet }}</td>
                  <td>{{ item.created_at }}</td>
                  <td class="align-center">
                    {{ item.processed }}
                  </td>
                  <td class="align-center">
                    {{ item.paid === 0 ? 'Non' : 'Oui' }}
                  </td>
                  <td class="align-center">
                    <v-btn
                      v-if="item.paid === 0"
                      outlined
                      color="info"
                      :loading="item.loading"
                      @click="setPayment(item.id, ind, true)"
                    >
                      Marquer comme payé
                    </v-btn>
                    <v-btn
                      v-else
                      outlined
                      color="primary"
                      :loading="item.loading"
                      @click="setPayment(item.id, ind, false)"
                    >
                      Marquer comme impayé
                    </v-btn>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.open"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      outlined
      centered
      text
    >
      <div class="text-center">
        {{ snackbar.text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>

import axios from '@axios'
import { emailValidator, required } from '@core/utils/validation'
import {
  mdiCheckBold, mdiCloudUpload, mdiDatabaseImport, mdiPlus,
} from '@mdi/js'
import {
  onMounted, ref,
} from '@vue/composition-api'
import { read, utils } from 'xlsx'
import useExcel from '@/@core/utils/excel'

export default {
  setup() {
    const imports = ref([])
    let sp = []
    const loading = ref({
      imports: false,
      conseillers: false,
      conseiller: false,
      excelFile: false,
      processImport: false,
    })

    const dialogs = ref({
      imports: false,
      conseiller: false,
    })

    const snackbar = ref({
      open: false,
      color: 'success',
      text: '',
      timeout: 3500,
    })

    const nbWaitingImports = ref(0)
    const importPercent = ref(0)
    let itemsToImport = []

    const cgp = ref(null)
    const cgpError = ref(false)

    const conseillers = ref([])
    const conseillerFormData = ref({})
    const errorField = ref({})
    const form = ref(null)
    const dragover = ref(false)
    const error = ref(false)
    const disabled = ref(false)
    const importErrors = ref([])
    const processFinished = ref(false)

    const processedImports = ref(0)
    let remoteProcessed = 0
    let batch = 0
    let groups = 0
    let maxInGroup = 10
    let start = 0
    let stop = maxInGroup
    let importId = null

    const accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const { mapData } = useExcel()

    const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min
    const randomElem = arr => arr[randomInt(0, arr.length - 1)]

    const codeGenerate = () => {
      const pattern = '#####-#####'
      const charsets = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const code = pattern.split('').map(char => {
        if (char === '#') {
          return randomElem(charsets)
        }

        return char
      }).join('')

      return code
    }

    const getImports = async () => {
      loading.value.imports = true
      await axios.get('imports')
        .then(response => {
          imports.value = response.data.map(item => ({ ...item, loading: false }))
        })
        .catch(() => {
          snackbar.value.open = true
          snackbar.value.color = 'error'
          snackbar.value.text = 'Impossible de charger la liste des imports.'
          snackbar.value.timeout = 3500
        })
        .finally(() => {
          loading.value.imports = false
        })
    }

    const loadConseillers = async () => {
      loading.value.conseillers = true
      await axios.get('conseillers/list')
        .then(response => {
          conseillers.value = response.data
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          loading.value.conseillers = false
        })
    }

    const removeFile = () => {
      itemsToImport = []
      nbWaitingImports.value = 0
    }

    const onDialogInput = val => {
      processFinished.value = false
      processedImports.value = 0
      importPercent.value = 0
      importErrors.value = []
      remoteProcessed = 0
      removeFile()

      if (val === true && conseillers.value.length === 0) {
        loadConseillers()
      }
    }

    const onDialogConseiller = val => {
      if (val === true) {
        errorField.value = {}
        conseillerFormData.value = {
          cabinet: '',
          responsable: '',
          telephone: '',
          email: '',
          nom_referent: null,
          telephone_referent: null,
          email_referent: null,
        }

        if (form.value) {
          form.value.resetValidation()
        }
      }
    }

    const onSubmit = async () => {
      const conseiller = { ...conseillerFormData.value }
      errorField.value = {}

      const bon = {
        bon_id: null,
        code: codeGenerate(),
        nb_bons: 0,
      }

      await axios
        .post('conseillers/add-with-bon', { conseiller, bon, notify: false })
        .then(response => {
          dialogs.value.conseiller = false
          snackbar.value.open = true
          snackbar.value.color = 'success'
          snackbar.value.text = 'Cabinet CGP ajouté !'
          snackbar.value.timeout = 1500
          dialogs.value.conseiller = false
          conseillers.value.push({ id: response.data.data.id, cabinet: response.data.data.cabinet })
          cgp.value = response.data.data.id
        })
        .catch(() => {
          snackbar.value.open = true
          snackbar.value.color = 'error'
          snackbar.value.text = 'Une erreur est survenue, impossible d\'ajouter le cabinet de CGP !'
          snackbar.value.timeout = 3500
          Object.assign(errorField.value, error.response.data.errors)
        })

      form.value.resetValidation()
    }

    const checkFile = file => {
      if (file.type !== accept) {
        snackbar.value.open = true
        snackbar.value.color = 'error'
        snackbar.value.text = `Le fichier ${file.name} n'est pas au bon format (xlsx autorisé uniquement).`
        snackbar.value.timeout = 3500

        error.value = true

        return false
      }

      return true
    }

    const selectFile = () => {
      document.getElementById('import-file').click()
    }

    const proceedExcel = async file => {
      loading.value.excelFile = true
      disabled.value = true
      processedImports.value = 0
      importPercent.value = 0
      processFinished.value = false
      importErrors.value = []
      remoteProcessed = 0
      const workbook = await file.arrayBuffer()
      const wb = read(workbook)
      const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 'A', raw: false })

      await mapData(data, sp)
        .then(values => {
          itemsToImport = values
          nbWaitingImports.value = values.length
        })
        .catch(e => {
          snackbar.value.open = true
          snackbar.value.color = 'error'
          snackbar.value.text = e
          snackbar.value.timeout = 10000
        })
        .finally(() => {
          loading.value.excelFile = false
          disabled.value = false
        })
    }

    const counter = (s, end, duration = 500) => {
      let current = s
      const range = end - s
      const increment = end > s ? 1 : -1
      const step = Math.abs(Math.floor(duration / range))

      const timer = setInterval(() => {
        current += increment
        processedImports.value = current
        if (current === end) {
          clearInterval(timer)
        }
      }, step)

      return current
    }

    const setImportDefault = () => {
      loading.value.processImport = false
      importId = null

      batch = 0
      groups = 0
      start = 0
      stop = maxInGroup
    }

    const processImport = async () => {
      if (batch >= groups) {
        setImportDefault()

        return
      }

      const currentData = itemsToImport.slice(start, stop)

      await axios.post('adhesions/imports', { data: currentData, import_id: importId, conseiller_id: cgp.value })
        .then(response => {
          if (response.data.errors.length > 0) {
            response.data.errors.forEach(e => {
              const errorMsg = {}
              if (itemsToImport[e.index].adherent.societe !== null) {
                errorMsg.adherent = `Impossible d'importer ${itemsToImport[e.index].adherent.societe}`
              } else {
                errorMsg.adherent = `Impossible d'importer  ${itemsToImport[e.index].adherent.nom} ${itemsToImport[e.index].adherent.prenom}`
              }
              errorMsg.msg = ` : ${e.msg}`
              importErrors.value.push(errorMsg)
            })
          }
          remoteProcessed += response.data.processed
        })
        .catch(() => {
          for (let i = start; i <= stop; i += 1) {
            const errorMsg = {}
            if (itemsToImport[i].adherent.societe !== null) {
              errorMsg.adherent = `Impossible d'importer ${itemsToImport[i].adherent.societe}`
            } else {
              errorMsg.adherent = `Impossible d'importer ${itemsToImport[i].adherent.nom} ${itemsToImport[i].adherent.prenom}`
            }
            errorMsg.msg = ' : erreur serveur.'
            importErrors.value.push(errorMsg)
          }
        })
        .finally(async () => {
          counter(start, start + currentData.length)
          batch += 1
          const percent = ((start + currentData.length) / nbWaitingImports.value) * 100
          importPercent.value = parseInt(percent, 10)

          if (batch >= groups) {
            // import terminé
            await axios.patch(`imports/${importId}`, { processed: remoteProcessed })
            remoteProcessed = 0
            processFinished.value = true
            setImportDefault()
            getImports()
          } else {
            start = stop
            stop += maxInGroup
            processImport()
          }
        })
    }

    const callProcessImport = async () => {
      cgpError.value = false

      maxInGroup = 10

      if (nbWaitingImports.value >= 100) {
        maxInGroup = 20
      }

      if (nbWaitingImports.value >= 500) {
        maxInGroup = 40
      }

      groups = parseInt(nbWaitingImports.value / maxInGroup, 10) + ((nbWaitingImports.value % maxInGroup) > 0 ? 1 : 0)
      if (batch >= groups) {
        setImportDefault()

        return
      }

      if (cgp.value === null) {
        cgpError.value = true

        return
      }

      loading.value.processImport = true

      await axios.post('imports', { conseiller_id: cgp.value })
        .then(response => {
          importId = response.data.id
        })
        .catch(() => {
          snackbar.value.open = true
          snackbar.value.color = 'error'
          snackbar.value.text = 'Une erreur est survenue, impossible d\'enregistrer le nouvel import !'
          snackbar.value.timeout = 3000
        })
        .finally(() => {
          processImport()
        })
    }

    const onFileSelected = () => {
      const input = document.getElementById('import-file')
      proceedExcel(input.files[0])
    }

    const onDrop = e => {
      if (disabled.value) {
        return
      }
      dragover.value = false
      error.value = false

      let file = null

      if (e.dataTransfer.items.length) {
        file = e.dataTransfer.items[0].getAsFile()
      } else {
        // eslint-disable-next-line prefer-destructuring
        file = e.dataTransfer.files[0]
      }

      if (checkFile(file)) {
        proceedExcel(file)
      }
    }

    const setPayment = async (id, index, paid) => {
      imports.value[index].loading = true
      await axios.patch(`imports/payment/${id}`, { paid })
        .then(() => {
          imports.value[index].paid = paid ? 1 : 0
          snackbar.value.open = true
          snackbar.value.color = 'success'
          snackbar.value.text = 'Paiement appliqué à tous les adhérents importés concernés.'
          snackbar.value.timeout = 2000
        })
        .catch(() => {
          snackbar.value.open = true
          snackbar.value.color = 'error'
          snackbar.value.text = 'Une erreur est survenue, imposible d\'appliquer les paiements'
          snackbar.value.timeout = 2500
        })
        .finally(() => {
          imports.value[index].loading = false
        })
    }

    const setCgp = () => {
      cgpError.value = cgp.value === null
    }

    onMounted(async () => {
      getImports()

      await axios.get('parts')
        .then(response => {
          sp = response.data
        })
        .catch(() => {
          snackbar.value.open = true
          snackbar.value.color = 'error'
          snackbar.value.text = 'Impossible de charger la liste des SP (nécessaires pour les imports), merci de recharger la page.'
          snackbar.value.timeout = 5000
        })
    })

    return {
      imports,
      loading,
      dialogs,
      mdiPlus,
      mdiCloudUpload,
      mdiDatabaseImport,
      mdiCheckBold,
      conseillers,
      onDialogInput,
      onSubmit,
      validators: {
        required,
        emailValidator,
      },
      onDialogConseiller,
      conseillerFormData,
      form,
      errorField,
      snackbar,
      cgp,
      accept,
      dragover,
      onDrop,
      selectFile,
      onFileSelected,
      error,
      disabled,
      nbWaitingImports,
      removeFile,
      processImport,
      importErrors,
      importPercent,
      callProcessImport,
      processedImports,
      processFinished,
      cgpError,
      setPayment,
      setCgp,
    }
  },
}
</script>

<style scoped>
.el-upload__input {
  display: none;
}
.active.dragover {
  border-style: dashed;
  border-color: #b7a088;
}

.on-error {
  border-color: red;
}

.excel-file:not(.active) .v-card__text > div {
  opacity: 0.3;
}

.errors {
  max-height: 150px;
  overflow-x: auto;
  padding: 15px;
  border: 1px solid red;
  border-radius: 5px;
  text-align: left;
}

</style>
