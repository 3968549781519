/* eslint-disable no-unused-expressions */
import { utils } from 'xlsx'

export default function useExcel() {
  const countries = ['', 'FR', 'BE', 'LU', 'CH', 'MC', 'AD', 'GP', 'MQ', 'NC', 'PF', 'YT', 'PM', 'RE', 'SM', 'TF', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', '', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LV', 'LY', 'MA', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PG', 'PH', 'PK', 'PL', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'YE', 'ZA', 'ZM', 'ZW']

  const ec = (r, c) => utils.encode_cell({ r, c })

  const deleteRow = (ws, rowIndex) => {
    const variable = utils.decode_range(ws['!ref'])
    // eslint-disable-next-line no-plusplus
    for (let R = rowIndex; R < variable.e.r; ++R) {
      // eslint-disable-next-line no-plusplus
      for (let C = variable.s.c; C <= variable.e.c; ++C) {
        // eslint-disable-next-line no-param-reassign
        ws[ec(R, C)] = ws[ec(R + 1, C)]
      }
    }
    // eslint-disable-next-line no-plusplus
    variable.e.r--
    // eslint-disable-next-line no-param-reassign
    ws['!ref'] = utils.encode_range(variable.s, variable.e)
  }

  const formatDate = date => {
    // console.log(date)
    if (date === undefined || !date.includes('/')) {
      return null
    }
    const d = date.split('/')

    if (d[2].length < 4) d[2] = `19${d[2]}`
    if (d[1].length < 2) d[1] = `0${d[1]}`
    if (d[0].length < 2) d[0] = `0${d[0]}`

    return [d[2], d[0], d[1]].join('-')

    /*
    const d = new Date(date)
    let month = `${d.getMonth() + 1}`
    let day = `${d.getDate()}`
    const year = d.getFullYear()

    if (month.length < 2) month = `0${month}`
    if (day.length < 2) day = `0${day}`

    return [year, month, day].join('-')
    */
  }

  const mapData = (data, sp) => {
    const mapped = []
    let entry = {}
    let id = null
    let index = -1
    let emailAdherent = ''

    const cgp = 1

    return new Promise((resolve, reject) => {
      data.shift()
      data.forEach(item => {
        // console.log(item)
        if (item.A === undefined) {
          let Emsg = ''
          if (id !== null) {
            Emsg = ` après l'ID ${id}`
          }
          reject(new Error(`Un identifiant est manquant${Emsg}. Impossible de poursuivre.`))
        }
        if (id !== item.A) {
          emailAdherent = item.F
          let nationalite = null
          const pays = item.M.split('|')
          entry = {}
          index += 1
          entry.index = index
          entry.adhesion = {
            bon_id: cgp,
            cotisation: 0,
            valide: 1,
            lang: 'fr',
          }

          if (item.N) {
            nationalite = +item.N.split('|')[1]
          }

          entry.adherent = {
            nationalite_id: nationalite,
            societe: item.B || null,
            siret: item.C || null,
            nom: item.E || null,
            prenom: item.D || null,
            email: emailAdherent,
            date_de_naissance: formatDate(item.G),
            ville_naissance: item.H || null,
            profession: item.I || null,
            adresse1: item.J,
            codepostal: item.K,
            ville: item.L,
            pays: pays[0],
            code_pays: countries[pays[1]],
            telephone: item.O,
            statut: item.B === undefined ? 'particulier' : 'societe',
          }
          entry.representantLegal = []
          entry.sidepockets = []
        }

        if (item.P !== undefined) {
          const fond = item.P.split('|')
          const part = item.Q.split('|')

          if (fond.length < 2 || part.length < 2) {
            reject(new Error(`Un identifiant est manquant dans un fond ou une part pour l'ID ${item.A}`))
          }
          const partId = +part[1]

          entry.sidepockets.push({
            fond_id: +fond[1],
            part_id: partId,
            nom_fond: fond[0],
            nom_part: sp[partId].nom,
            nom_etablissement: '-',
            nb_parts: item.R,
            montant_part: sp[partId].montant,
            total_parts: sp[partId].montant * item.R,
            mode_detention_id: 3,
            mode_detention: 'compte titre',
          })
        }

        if (item.S !== undefined) {
          const paysR = item.AA.split('|')
          entry.representantLegal.push({
            nationalite_id: +item.AB.split('|')[1],
            nom: item.T,
            prenom: item.S,
            email: emailAdherent,
            date_de_naissance: formatDate(item.U),
            ville_naissance: item.V,
            profession: item.W,
            adresse1: item.X,
            codepostal: item.Y,
            ville: item.Z,
            pays: paysR[0],
            code_pays: countries[paysR[1]],
            telephone: '-',
          })
        }

        if (id !== item.A) {
          mapped.push(entry)
          id = item.A
        }
      })

      return resolve(mapped)
    })
  }

  return {
    deleteRow,
    mapData,
  }
}
